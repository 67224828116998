import React from 'react';
import { Link } from 'gatsby';
import { include as includes } from 'underscore.string';
import find from 'lodash/find';
import intersect from 'just-intersect';
import { getTags } from '../utils';
import Summary from '../components/Summary';
import PropTypes from 'prop-types';

class ReadNext extends React.Component {
	render() {
		const { pages, post } = this.props;
		let { readNext } = post;
		let nextPost;

		console.log('nextPost ****');

		if (readNext) {
			nextPost = find(pages, (page) => includes(page.path, readNext));
		} else {
			console.log('post excerpt', post.excerpt);
			console.log('filter', pages.filter((p) => p.tags && p.excerpt !== post.excerpt));
			readNext = pages
				.filter((p) => p.tags && p.excerpt !== post.excerpt)
				.map((p) => {
					if (post.tags) {
						const t = getTags(p);
						p.diff = intersect(post.tags, t).length;
					}
					return p;
				})
				.sort((a, b) => a.diff - b.diff)
				.slice(-5)
				.sort(() => Math.random() * -0.5)
				.pop();
			if (readNext) {
				readNext = readNext.path;
				nextPost = find(pages, (page) => includes(page.path, readNext));
			}
		}

		if (!nextPost) {
			return React.createElement('noscript', null);
		} else {
			nextPost = find(pages, (page) => includes(page.path, readNext.slice(1, -1)));

			return (
				<div
					style={{
						paddingTop: '1em',
						paddingBottom: '1em'
					}}
				>
					<h6 style={{ marginBottom: 0 }}>READ THIS NEXT</h6>

					<h3 style={{ marginTop: 0, marginBottom: 0 }}>
						<Link to={nextPost.path}>{nextPost.title}</Link>
					</h3>

					<Summary body={nextPost.excerpt} />
				</div>
			);
		}
	}
}

ReadNext.propTypes = {
	post: PropTypes.object.isRequired,
	pages: PropTypes.array
};

export default ReadNext;
