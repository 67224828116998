import React from 'react';
import { graphql } from 'gatsby';
import DocumentTitle from 'react-document-title';
import Template from './index';

import ReadNext from '../components/ReadNext';
import Bio from '../components/Bio';

export default function BlogTemplate(props) {
	console.log('props props', props);
	const { pathContext: { allResults } } = props;
	const { markdownRemark } = props.data; // data.markdownRemark holds our post data
	const { frontmatter, html, excerpt } = markdownRemark;
	const { site: { siteMetadata: { blogTitle, authorName, authorBio } } } = props.data;
	const post = frontmatter;

	const pages = allResults.filter((page) => page.layout === post.layout);
	console.log('psages', pages);
	return (
		<Template {...props}>
			<DocumentTitle title={frontmatter.title ? `${frontmatter.title} | ${blogTitle}` : blogTitle}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						maxWidth: '40rem',
						paddingLeft: '1em',
						paddingRight: '1em',
						marginLeft: 'auto',
						marginRight: 'auto'
					}}
				>
					<div
						className="markdown"
						style={{
							maxWidth: '90vw'
						}}
					>
						<h1>{frontmatter.title}</h1>

						{/* <div className="article" ref="markdown" dangerouslySetInnerHTML={{ __html: html }} /> */}
						<div className="blog-post-content" dangerouslySetInnerHTML={{ __html: html }} />

						<hr style={{ marginTop: '1.25em' }} />

						<Bio authorName={authorName} authorBio={authorBio} />

						<hr />

						<ReadNext post={{ ...post, excerpt }} pages={pages} />
					</div>
				</div>
			</DocumentTitle>
		</Template>
	);
}
export const pageQuery = graphql`
	query($path: String!) {
		markdownRemark(frontmatter: { path: { eq: $path } }) {
			html
			excerpt(pruneLength: 250)
			frontmatter {
				date
				path
				title
				tags
				layout
			}
		}
		site {
			siteMetadata {
				title
				description
				authorName
				subTitle
				authorBio
				gaCode
				blogTitle
			}
		}
	}
`;
