import React from 'react';
import styled from 'styled-components';

const AuthorInfo = styled.div`
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	margin-bottom: 2em;
`;

const Bio = (props) => (
	<AuthorInfo>
		<img
			src={'/author.jpg'}
			alt={props.authorName}
			style={{
				borderRadius: '50%',
				maxWidth: 132,
				display: 'block',
				marginBottom: '1.25em',
				marginTop: '1em',
				width: 'inherit'
			}}
		/>
		<div dangerouslySetInnerHTML={{ __html: props.authorBio }} />
	</AuthorInfo>
);

export default Bio;
